import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  Divider,
  CardContent,
  Card,
} from "@mui/material";
import axiosInstance from "./../../../api/axios/axiosInstance";

const MachineEventTotals = () => {
  const { machineId } = useParams();
  const [newData, setNewData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    axiosInstance
      .post(
        "/machine-events-totals",
        { machine_id: machineId },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setNewData(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [machineId]);

  const responseMappedForDisplay = (data) => {
    if (!data) return null;

    return {
      totalEvents: data.totalEvents,
      falseEvents: data.totalEvents - data.realEvents,
      realEvents: data.realEvents,
      catEvents: data.catEvents,
      labelledEvents: data.labelledEvents,
      errorEvents: data.errorEvents,
      factoryResetCount: data.factoryResetCount ?? 0,
    };
  };

  const eventData = responseMappedForDisplay(newData?.data);

  return (
    <Box sx={{ padding: 2 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              {eventData &&
                Object.entries(eventData).map(([key, value], index) => (
                  <React.Fragment key={key}>
                    <Grid item xs>
                      <Box
                        sx={{
                          padding: "16px",
                          textAlign: "center",
                        }}
                      >
                        <Tooltip title={key.replace(/([A-Z])/g, " $1").trim()}>
                          <Typography variant="body1" color="textSecondary">
                            {key.replace(/([A-Z])/g, " $1").trim()}
                          </Typography>
                        </Tooltip>
                        <Typography variant="h5">
                          {value.toLocaleString()}
                        </Typography>
                      </Box>
                    </Grid>
                    {index < Object.entries(eventData).length - 1 && (
                      <Divider orientation="vertical" flexItem />
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default MachineEventTotals;
