import React, { useState, useEffect } from "react";
import "./Events.css";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import DeviceSnSuffixSearch from "../../../../utils/DeviceSnSuffixSearch";
import EventFilterPopover from "../../../../utils/EventFilterPopover";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSearchParams } from "../../../../utils/useSearchParams";
import axiosInstance from "../../../../api/axios/axiosInstance";
import LoadingSpinner from "../../../LoadingSpinner/component";
import EventTable from "../../../Table/EventsTable/EventsTable";


const EventsHome = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [newData, setNewData] = React.useState();
  const [newDataRevision, setNewDataRevision] = React.useState();
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    pageSize: 50,
    snSuffix: "",
    eventType: "",
    sort: "START_TIME_DESC"
  })
  const [isLoading, setisLoading] = React.useState(true);

  const filterOptions = [
    
    {
      name: "eventType",
      label: "Event Type",
      values: ["ERROR", "TRIGGERED", "TARE"],
    },
    {
      name: "sort",
      label: "SORT",
      values: ["START_TIME_DESC", "START_TIME_ASC"],
    },
    {
      name: "withTags",
      label: "With Tags",
      values: ["urination", "defecation"],
    },
    {
      name: "withoutTags",
      label: "Without Tags",
      values: ["urination", "defecation"],
    },
  ];

  const getAllSearchParams = (key) => {
    const resultsArray = searchParams.getAll(key)
    if (resultsArray.length === 0) return null

    return resultsArray
  }
  


  useEffect(() => {
    setisLoading(true);
    let withTags = ""
    let withoutTags = ""

    // const queryParams = new URLSearchParams({
    //   page: query.page.toString(),
    //   perPage: query.perPage.toString(),
    //   // machineSns: Array.isArray(query.machineSns) ? query.machineSns.join(',') : '',
      // eventType: query.eventType || '',
    //   sort: query.sort || '',
    //   // withTags: Array.isArray(query.withTags) ? query.withTags.join(',') : '',
    //   // withoutTags: Array.isArray(query.withoutTags) ? query.withoutTags.join(',') : '',
    //   // from: query.from ? query.from.format('YYYY-MM-DD') : '',
    //   // to: query.to ? query.to.format('YYYY-MM-DD') : '',
    // });
    if (searchParams.get("withTags") != null)
    {
      withTags = searchParams.get("withTags")
    }
    if (searchParams.get("withoutTags") != null)
    {
      withoutTags = searchParams.get("withoutTags")
    }
    let url = "/event-listing?page=" + searchParams.get("page") +
      "&perPage=" + searchParams.get("pageSize")
      + "&machineSns=" + searchParams.get("snSuffix")
      + "&sort=" + searchParams.get("sort")  + "&eventType=" + searchParams.get("eventType")
      + "&withTags=" + withTags + "&withoutTags=" + withoutTags
    
    axiosInstance.get(url)
      .then((response, error) => {
        setisLoading(false);
        setNewData(response.data);
        setError(error);
      }).catch((error) => {
        setisLoading(false);
      })



  }, [searchParams])

  const handleSearch = (newSnSuffix) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffix: newSnSuffix.trim(),
    }
    if (newSnSuffix === "") {
      delete newSearchParams["snSuffix"]
      
    }
    setisLoading(true)
    setSearchParams(newSearchParams);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterApply = (appliedFilters) => {
    setSearchParams(appliedFilters)
    setFilters(appliedFilters);
    setFilterAnchorEl(null);
    // Add logic to apply the filters to your data fetching logic
  };

  const pagination = {
    current: searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 0,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 0,
    onChange: (page, pageSize) => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page,
        pageSize,
      })
    },
    total: parseInt(newData?.pageInfo.totalCount ?? 0),
  }

  const dataTransformedForTable = newData?.data.map((monitor) => {
    return {
      ...monitor,
      key: monitor.id,
      startTime: monitor.startTime ?? ""
    }
  }) ?? []


  const [filters, setFilters] = useState({});
  return !isLoading ? (
    <div className="module-content">
      <h5>Events</h5>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs>
              <DeviceSnSuffixSearch onSearch={handleSearch} defaultValue={searchParams.get("snSuffix") ?? ""} placeholder="Input Event ID" />
            </Grid>
            <Grid item>
              <IconButton onClick={handleFilterClick}>
                <FilterAltIcon />
              </IconButton>
            </Grid>
          </Grid>
          <br></br>

          <Grid container>
            <EventTable
              data={dataTransformedForTable}
              loading={false}
              pagination={pagination}
            />
          </Grid>
        </CardContent>
      </Card>
      <EventFilterPopover
        filterOptions={filterOptions}
        anchorEl={filterAnchorEl}
        handleClose={handleFilterClose}
        handleApply={handleFilterApply}
      />
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default EventsHome;
