import React, { useState } from "react";
import ModuleCard from "../ModuleCard/ModuleCard";
import catLitterBox from "./../../assets/images/catlitterbox.png";
import catFeeder from "./../../assets/images/catbowl.png";
import dogbowl from "./../../assets/images/dogbowl.png";
import dogcollar from "./../../assets/images/dogcollar.png";
import axiosInstance from "../../api/axios/axiosInstance";

import "./Home.css";

const Home = () => {

  const [data, setNewData] = useState();
  const [error, setError] = useState('');

  React.useEffect(() => {
    axiosInstance.post('/roles', {},{headers:{
        "Content-Type": "multipart/form-data",
    }})
        .then((response, error) => {
            if (error) {
              setError(error)
                return;
            }
            setNewData(response.data);
        });
}, [])

const notificationsLinkVisible = data?.roles?.includes("message_sender")
let PTCLinkVisibleRoles = false
if (typeof(data) !== 'undefined'){
    const roles= data["data"]["roles"]
    PTCLinkVisibleRoles = roles?.includes("ptc_users")
}
localStorage.setItem("enable_read_write", PTCLinkVisibleRoles)

  return (
    <div className="home-container">
      <div className="content">
        <div className="main-content">
          <ModuleCard
            image={catLitterBox}
            title="Cat Litter Box"
            subMenu={["Households", "Monitors", "Cats", "Events", "Location"]}
          />
          <ModuleCard
            image={catFeeder}
            title="Cat Feeder"
            subMenu={["Monitors", "Events"]}
          />
          <ModuleCard
            image={dogcollar}
            title="Dog Collar"
            subMenu={["Monitors", "Dogs", "Events"]}
          />
          <ModuleCard
            image={dogbowl}
            title="Dog Bowl"
            subMenu={["Dogs", "Events"]}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
