import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    IconButton,
    Paper,
    TablePagination,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";
import { Edit as EditIcon } from "@mui/icons-material";
import EditMonitorGroup from "../../Modules/HouseholdSingleView/EditMonitorGroup";
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from "../../../api/axios/axiosInstance";

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    sn: "SN",
    name: "NAME",
    power: "BATTERY_PERCENTAGE",
    mostRecentUpload: "MOST_RECENT_UPLOAD_AT",
    stFirmwareRevision: "ST_FIRMWARE_REVISION",
    espFirmwareRevision: "ESP_FIRMWARE_REVISION",
    hardwareRevision: "HARDWARE_REVISION",
    wifiRssi: "WIFI_RSSI",
    firstConnectedAt: "FIRST_CONNECTED_AT",
    snFirstConnectedAt: "SN_FIRST_CONNECTED_AT",
    frozenAt: "FROZEN_AT",
};

const SORT_DIRS = {
    ascend: "ASC",
    descend: "DESC",
};

const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const MachinesGroupTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [edit, setEdit] = React.useState(false);
    const [machineGroup, setmachineGroup] = React.useState(false);
    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        if (!appliedSort.includes(columnSortField)) return null;
        const direction = getDirectionFromSortString(appliedSort);
        if (!direction) return null;
        return direction;
    };

    const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
        const getPowerModeFilters = () => {
            const powerModeFilters = searchParams
                .getAll("powerMode")
                .map((filter) => "powerMode." + filter);
            const showBatteryWarningFilters = searchParams
                .getAll("showBatteryWarning")
                .map((filter) => "showBatteryWarning." + filter);

            return powerModeFilters.concat(showBatteryWarningFilters);
        };
        const getMostRecentUploadFilters = () => {
            const mostRecentUploadWarning = searchParams.get(
                "mostRecentUploadWarning"
            );
            if (mostRecentUploadWarning === "true")
                return ["mostRecentUploadWarning.true"];
            if (mostRecentUploadWarning === "false")
                return ["mostRecentUploadWarning.false"];
            return [];
        };
        const getStFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("stFirmwareRevision");
            return revisions;
        };
        const getEspFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("espFirmwareRevision");
            return revisions;
        };
        const getHardwareRevisionFilters = () => {
            return searchParams.getAll("hardwareRevision");
        };
        const getFrozenAtFilters = () => {
            const isFrozen = searchParams.get("isFrozen");
            if (isFrozen === "true") return ["isFrozen.true"];
            if (isFrozen === "false") return ["isFrozen.false"];
            return [];
        };
        const getOrphanedAtFilters = () => {
            const isOrphaned = searchParams.get("isOrphaned");
            if (isOrphaned === "true") return ["isOrphaned.true"];
            if (isOrphaned === "false") return ["isFrozen.false"];
            return [];
        };

        return {
            powerMode: getPowerModeFilters(),
            mostRecentUpload: getMostRecentUploadFilters(),
            stFirmwareRevision: getStFirmwareRevisionFilters(),
            espFirmwareRevision: getEspFirmwareRevisionFilters(),
            hardwareRevision: getHardwareRevisionFilters(),
            frozenAt: getFrozenAtFilters(),
            orphanedAt: getOrphanedAtFilters(),
        };
    };
    const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(
        searchParams
    );
    const getDefaultFilteredValuesForColumn = (colIndex) =>
        defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, sorter) => {
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };


        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    const [isLoading, setisLoading] = React.useState(false);

    const url = `/delete-household-machines-groups`;

    const setselectedRecord = async(record) => {
        setEdit(true)
        setmachineGroup(record)
    }

    const setIsModalDelete = async(record) => {
        setisLoading(true);
        const request_data = {
            "household_id": record["household_id"],
            "group_name": record["group_name"]
        }
        axiosInstance.post(url, request_data).then((response, error) => {
            if (response.data.message == 'Delete successful') {
                console.log('Successfully updated the Household Group')
                setisLoading(false);
                updateParentState(isupdateTable+1)
            }
        })
    }

    let columns = [
        {
            title: "Group Name",
            dataIndex: "group_name",
            width: 200,
            render: function MachineSerial(group_name, record, index) {
                return (
                    <div style={{ display: "flex" }}>
                        <span data-testid={index}>
                            {group_name ?? "-"}
                        </span>
                    </div>
                );
            },
        },
        {
            title: "Serial No.",
            dataIndex: "device_list",
            render: function MonitorName(device_list, record) {
                if (!device_list || device_list?.length === 0)
                    return <div className={styles.noData}>No Monitors</div>
                return (
                    <ul className={styles.ul}>
                        {device_list.map((device, index) => (
                            <li key={[device, index].join()}>{device ?? "-"}</li>
                        ))}

                    </ul>

                )
            },
        },

        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: function renderActionButton(name, record) {
                return (
                    <Box display="flex">
                        <EditIcon onClick={() => setselectedRecord(record)} style={{ color: "#1976d2", cursor: "pointer" }} />
                        <DeleteIcon onClick={() => setIsModalDelete(record)} style={{ color: "#1976d2", cursor: "pointer" }} Tooltip="Delete"/>
                        {edit ? (
                            <EditMonitorGroup
                                machine={machineGroup}
                                submitCallback={() => {
                                    updateParentState(isupdateTable + 1)
                                    setEdit(false);
                                }}
                                closeCallback={() => {
                                    setEdit(false);
                                }}
                            />
                        ) : null}
                    </Box>

                );
            },
        },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }
    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                ...defaultPagination,
                ...pagination,
            };

    return (
        <FullWidthPaper>
            <FullWidthTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={true}
                                            direction={getDefaultSortOrderForColumn(column.dataIndex) || 'ascend'}
                                            onClick={() => forwardTableChangeToSearchParams(pagination, column.sorter)}
                                        // onChange={forwardTableChangeToSearchParams}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default MachinesGroupTable;
