import React, { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import c from "classnames"
import { useTheme } from "@mui/material/styles";
import { createUseStyles } from "react-jss"
import { NumberParam, useQueryParams, withDefault } from "use-query-params"

// import { tids } from "~/testIds"

const useStyles = createUseStyles((theme) => {
    return {
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "16px 0 72px 0",
        },
        controls: {
            display: "flex",
            justifyContent: "center",
        },
        errors: {
            ...theme.typography.caption,
            textAlign: "center",
            color: theme.palette.error.main,
            marginTop: "8px",
        },
        button: {
            ...theme.typography.button,
            // color: theme.colors.black[600],
            color: theme.palette.error.main,
            padding: "0 1rem",
            height: "32px",
            background: "#FFFFFF",
            border: `1px solid #666666`,
            borderRadius: "4px",
            "&:hover": {
                cursor: "pointer",
                background: "#666666",
            },
            "&:focus": {
                background: "#CCCCCC",
            },
        },
        hidden: {
            display: "none",
        },
        pageNumbers: ({ isSinglePage }) => ({
            ...theme.typography.caption,
            margin: "0 48px",
            color: theme.palette.error.main,
            // color: isSinglePage
            //     ? theme.colors.black[400]
            //     : theme.colors.black[900],
        }),
        input: ({ isSinglePage }) => ({
            ...theme.typography.caption,
            border: `1px solid ${
                isSinglePage ? "#999999" : "#666666"
            }`,
            // color: isSinglePage
            //     ? theme.colors.black[400]
            //     : theme.colors.black[900],
            // color: theme.palette.error.main,
            borderRadius: "4px",
            height: "32px",
            lineHeight: "32px",
            display: "inline-block",
            margin: "0",
            textAlign: "center",
            minWidth: "32px",
            background: isSinglePage
                ? "#E6E6E6"
                : "#FFFFFF",
            padding: "0 8px",
            "&:focus": {
                borderColor: "#6979F8",
                boxShadow: `0 0 0 4px "#A5AFFB3D"`,
                outline: "none",
            },
            "&:hover": {
                borderColor: "#B3B3B3",
            },
            "[disabled]": {
                border: "1xp solid red",
            },
        }),
        errorInput: {
            borderColor: "#FF647C !important",
        },
        disabled: {
            cursor: "wait !important",
            background: "#E6E6E6",
            borderColor: "#E6E6E6",
            color: "#CCCCCC",
            "&:hover": {
                background: "#E6E6E6",
                borderColor: "#E6E6E6",
                color: "#CCCCCC",
                // color: theme.palette.error.main,
            },
            "&:focus": {
                background: "#E6E6E6",
                borderColor: "#E6E6E6",
                color: "#CCCCCC",
                // color: theme.palette.error.main,
            },
        },
        leftIcon: {
            marginRight: "8px",
        },
        rightIcon: {
            marginLeft: "8px",
        },
        smallMarginBottom: {
            marginBottom: "16px",
        },
    }
})

const Pagination = ({
    total,
    disabled,
    setPage,
    smallMarginBottom,
    pageName = "page",
}) => {
    const theme = useTheme()
    const classes = useStyles({ isSinglePage: total === 1, theme })


    // const [query, setQuery] = useQueryParams({
    //     [pageName]: withDefault(NumberParam, 1),
    // })

    const [query, setQuery] = useState({
        [pageName]: 1
    })

    const current = query[pageName]

    const [pageError, setPageError] = useState()

    const inputRef = useRef()

    const isOutOfBounds = (n) => {
        if (n < 1) {
            console.log("Aborting because too small")
            return true
        }
        if (n > total) {
            console.log("Aborting because too big")
            return true
        }
        return false
    }

    const goToPage = (page) => {
        if (page === "") {
            console.log("Empty value for page, wait a second")
        } else if (isNaN(page)) {
            console.error("Page is not a number")
            setPageError("Please enter a number")
        } else if (page < 1 || page > total) {
            console.error("Page is too low or high")
            setPageError(`Please enter a number between 1 and ${total}`)
        } else {
            setPageError(null)

            if (isOutOfBounds(page)) {
                console.error("Page", page, "is out of bounds")
                return
            }

            try {
                setQuery({ [pageName]: parseInt(page) })
                setPage(parseInt(page))
            } catch (e) {
                console.error("Unable to change to page", page, e)
                return
            }
        }
    }

    const inputControl = async (e) => {
        if (
            (isNaN(String.fromCharCode(e.which)) &&
                ![37, 38, 39, 40, 8, 46].includes(e.which)) ||
            e.which === 32
        ) {
            // Not a number or delete key or arrow. Does include space
            e.preventDefault()
        } else if (e.which === 13) {
            // Enter key
            e.preventDefault()
            goToPage(e.target.textContent)
        }
        // Allow all other events (numbers)
    }

    // Display nothing if there are no pages to page through
    if (total <= 0) {
        return null
    }

    

    return (
        <div
            className={c(classes.container, {
                [classes.smallMarginBottom]: smallMarginBottom === true,
            })}
            // data-testid={
            //     disabled ? tids.pagination.loading : tids.pagination.loaded
            // }
        >
            <div className={classes.controls}>
                <div
                    style={{
                        width: "33%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <button
                        disabled={current <= 1 || disabled}
                        onClick={() => goToPage(current - 1)}
                        className={c(classes.button, {
                            [classes.hidden]: current <= 1,
                            [classes.disabled]: disabled,
                        })}
                        // data-testid={tids.pagination.prevButton}
                    >
                        <FontAwesomeIcon
                            className={classes.leftIcon}
                            icon="angle-left"
                        />
                        <span>Previous</span>
                    </button>
                </div>
                <span className={classes.pageNumbers}>
                    <span
                        tabIndex="0"
                        role="textbox"
                        contentEditable={total > 1}
                        suppressContentEditableWarning={true}
                        onKeyDown={inputControl}
                        onBlur={(e) => goToPage(e.target.textContent)}
                        className={c(classes.input, {
                            [classes.errorInput]: pageError,
                            [classes.disabled]: disabled,
                        })}
                        ref={inputRef}
                        disabled={disabled}
                        // data-testid={tids.pagination.input}
                    >
                        {current}
                    </span>{" "}
                    of{" "}
                    <span>
                        {total}
                    </span>
                </span>
                <div style={{ width: "33%" }}>
                    <button
                        disabled={current >= total || disabled}
                        onClick={() => goToPage(current + 1)}
                        className={c(classes.button, {
                            [classes.hidden]: current >= total,
                            [classes.disabled]: disabled,
                        })}
                        // data-testid={tids.pagination.nextButton}
                    >
                        <span>Next</span>
                        <FontAwesomeIcon
                            className={classes.rightIcon}
                            icon="angle-right"
                        />
                    </button>
                </div>
            </div>
            {pageError && (
                <div
                    className={classes.errors}
                    // data-testid={tids.pagination.error}
                >
                    <FontAwesomeIcon
                        className={classes.leftIcon}
                        icon="exclamation-triangle"
                    />
                    {pageError}
                </div>
            )}
        </div>
    )
}

export default Pagination
