import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  TablePagination,
  Grid,
  Button,
  Checkbox,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/system";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios/axiosInstance";
import moment from "moment-timezone";
import LoadingSpinner from "../../LoadingSpinner/component";
import { useSearchParams } from "../../../utils/useSearchParams";
import SessionIDSearch from "../../../utils/SessionIDSearch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTimezone } from "../../../utils/context/Timezone";

import { saveAs } from "file-saver";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DogCollarEventLabelModal from "./DogCollarEventLabelModal";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import AnalyticsSharpIcon from "@mui/icons-material/AnalyticsSharp";
import Pagination from "../../../utils/Pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useTableStyles = styled((theme) => ({
  noData: {
    opacity: 0.4,
    fontStyle: "italic",
  },
  ul: {
    margin: 0,
    paddingLeft: "1rem",
  },
  header: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[200],
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "0 10px",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    color: theme.palette.common.white,
    flex: 1,
  },
  filterIcon: {
    marginLeft: "auto",
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

const CustomPagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiTablePagination-toolbar": {
    justifyContent: "space-between",
  },
  "& .MuiTablePagination-actions": {
    marginLeft: theme.spacing(2),
  },
}));

const EventRow = ({ event, source }) => {
  const [newEventModalIsOpen, setEventModalIsOpen] = React.useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    video_download(event);
    download_movement_file(event);
  };
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = `${dateFormat} ${timeFormat}`;
  const [videoURL, setVideoURL] = React.useState();
  const [error, setError] = React.useState();
  const [sensorData, setsensorData] = useState(null);
  const [isLoading, setisLoading] = React.useState(true);
  const [isLoadingSensorCSV, setisLoadingSensorCSV] = React.useState(true);
  const [csvData, setCsvData] = useState(null);
  const timezone = useTimezone();
  const [isLoadingCSV, setisLoadingCSV] = React.useState(true);

  const CopyToClipboardButton = ({ textToCopy, testId }) => (
    <CopyToClipboard text={textToCopy}>
      <Tooltip title="Copy to clipboard">
        <IconButton data-testid={testId}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
  // TOTO: Remove Z addition after explicitly UTC times are provided on the API

  const download_movement_file = (event) => {
    axiosInstance
      .get(
        "/get-dog-collar-movement-file-download?event_id=" +
          event.id +
          "&source=" +
          event.source
      )
      .then((response, error) => {
        setisLoadingSensorCSV(false);
        if (error) {
          setError(error);

          return;
        }
        if (typeof response.data != "string") {
          setsensorData(null);
        } else {
          setsensorData(response.data);
        }
      })
      .catch((error) => {
        setisLoadingSensorCSV(false);
        toast.error("[API Error]: Request Failed with Status Code 500", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const video_download = (event) => {
    if (event.cloud_video_file_url != "") {
      axiosInstance
        .get(
          "/get-dog-collar-video-download?event_id=" +
            event.id +
            "&video_url=" +
            event.cloud_video_file_url
        )
        .then((response, error) => {
          if (error) {
            setError(error);
            setisLoading(false);
            return;
          }
          setisLoading(false);
          setVideoURL(response.data.data);
        })
        .catch((error) => {
          setisLoading(false);
          toast.error("[API Error]: Request Failed with Status Code 500", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        });
    }
  };

  const date = moment(event.startTime + "Z").format(format);

  const hasEntryTime = objectHasPropertyOfType(event, "entry", "string");
  const displayEntryTime = hasEntryTime
    ? moment(event.entry + "Z").format(format)
    : "";
  const hasExitTime = objectHasPropertyOfType(event, "exit", "string");
  const displayExitTime = hasExitTime
    ? moment(event.exit + "Z").format(format)
    : "";

  const hasEliminationStart = objectHasPropertyOfType(
    event,
    "video_start_time",
    "string"
  );

  const displayEliminationStart = hasEliminationStart
    ? moment(event.video_start_time + "Z")
        .tz(timezone)
        .format(format)
    : "";

  const hasEliminationEnd = objectHasPropertyOfType(
    event,
    "video_end_time",
    "string"
  );
  const displayEliminationEnd = hasEliminationEnd
    ? moment(event.video_end_time + "Z")
        .tz(timezone)
        .format(format)
    : "";

  const handleDownloadCSV = () => {
    window.location.href = csvData;
  };

  const handleDownload = () => {
    window.location.href = videoURL;
  };

  const handleDownloadSensorCSV = () => {
    const blob = new Blob([sensorData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = event.id + ".csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    let selectedEvents = localStorage.getItem("selectedEvents");
    let selectedVideos = localStorage.getItem("selectedVideos");
    if (e.target.checked == true) {
      const value = e.target.value;
      if (
        selectedEvents != " " &&
        selectedEvents != null &&
        selectedVideos != " " &&
        selectedVideos != null
      ) {
        selectedEvents = selectedEvents + "," + value.split("+")[0];
        selectedVideos = selectedVideos + "," + value.split("+")[1];
        localStorage.setItem("selectedEvents", selectedEvents);
        localStorage.setItem("selectedVideos", selectedVideos);
      } else {
        selectedEvents = value.split("+")[0];
        selectedVideos = value.split("+")[1];
        localStorage.setItem("selectedEvents", selectedEvents);
        localStorage.setItem("selectedVideos", selectedVideos);
      }
    } else {
      const value = e.target.value;
      selectedEvents = value.split("+")[0];
      selectedVideos = value.split("+")[1];
      selectedEvents = selectedEvents.replace(value, " ");
      selectedVideos = selectedVideos.replace(value, " ");
      localStorage.setItem("selectedEvents", selectedEvents);
      localStorage.setItem("selectedVideos", selectedVideos);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Checkbox
            checked={checked}
            onChange={(e) => [handleChange(e)]}
            value={event.id + "+" + event.cloud_video_file_url}
          ></Checkbox>
        </TableCell>
        <TableCell  onClick={() => handleExpandClick()} style={{ cursor: "default" }}>{date}</TableCell>
        <TableCell style={{ marginLeft: "50px" ,cursor: "default"}}  onClick={() => handleExpandClick()}>
          {displayEliminationStart + " - " + displayEliminationEnd}
        </TableCell>
        <TableCell style={{ marginLeft: "301px",cursor: "default" }}  onClick={() => handleExpandClick()}>{event.pet_name} </TableCell>
        <TableCell style={{ marginLeft: "134px",cursor: "default" }}  onClick={() => handleExpandClick()}>{event.source}</TableCell>
        <TableCell>
          <AnalyticsSharpIcon
            onClick={() => setEventModalIsOpen(true)}
            eventloadcell
            style={{
              color: "#1976d2",
              cursor: "pointer",
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick()}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#6c757d1a" }}>
        <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box padding={3}>
              <Typography variant="body2">
                <b>Device ID:</b> {event.device_serial_nos}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2" copyable>
                  <b>Event ID:</b> {event.id}
                </Typography>
                <CopyToClipboardButton
                  textToCopy={event.id}
                  testId={"tids.eventView.copyEventIdToClipboard"}
                />
              </Typography>
              <Typography variant="body2">
                <b>UTC:</b> {date}
              </Typography>
              <Typography variant="body2">
                <b>Binary Files:</b>
                {isLoadingSensorCSV ? (
                  <LoadingSpinner />
                ) : sensorData ? (
                  <a
                    onClick={handleDownloadSensorCSV}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    Download CSV
                  </a>
                ) : (
                  <span>No data available</span>
                )}
              </Typography>

              {videoURL ? (
                <Typography variant="body2">
                  <b>Video File Name:</b> {event.file_name}
                </Typography>
              ) : (
                <span>No Video available</span>
              )}
              <Typography variant="body2">
                <b>Session Video:</b>
                {videoURL ? (
                  <a
                    onClick={handleDownload}
                    download
                    style={{ color: "#096dd9", cursor: "pointer" }}
                  >
                    Download Video
                  </a>
                ) : (
                  <span>No Video available for Download</span>
                )}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {newEventModalIsOpen && (
        <DogCollarEventLabelModal
          isModalOpen={newEventModalIsOpen}
          closeModal={() => setEventModalIsOpen(false)}
          eventId={event.id}
          source={source}
        />
      )}
    </>
  );
};

const EventTable = ({
  events,
  isLoading,
  refreshEventList,
  page,
  setPage,
  total,
  source
}) => {
  const styles = useTableStyles();

  return (
    <Box sx={{ padding: 2 }}>
      <Pagination
        total={total}
        disabled={isLoading}
        setPage={setPage}
        pageName="eventsPage"
        smallMarginBottom
      />

      <TableContainer component={Paper}>
        <Table aria-label="event table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={styles.header}>Date</TableCell>
              <TableCell className={styles.header}>Video Start - End</TableCell>
              <TableCell className={styles.header}>Dog</TableCell>
              <TableCell className={styles.header}>Source</TableCell>
              <TableCell className={styles.header}>#</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              events.map((event) => <EventRow key={event.id} event={event} source={source} />)
            )}
          </TableBody>
        </Table>
        {events?.length == 0 && (
          <Typography
            variant="h6"
            style={{ marginLeft: "640px", width: "50%", padding: "10px" }}
          >
            {" "}
            No Data Found{" "}
          </Typography>
        )}
      </TableContainer>
    </Box>
  );
};

const objectHasPropertyOfType = (object, property, type) => {
  return (
    Object.prototype.hasOwnProperty.call(object, property) &&
    typeof object[property] === type
  );
};

const DogCollarEvents = () => {
  const isSmallScreen = useMediaQuery("(max-width:950px)");

  const { dogId, source } = useParams();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [merror, setError] = React.useState();

  const [searchParams, setSearchParams] = useSearchParams({
    snSuffixSessionID: "",
  });

  const handleSearch = (snSuffixSessionID) => {
    let newSearchParams = {
      ...Object.fromEntries(searchParams),
      page: 1,
      snSuffixSessionID: snSuffixSessionID.trim(),
    };
    if (snSuffixSessionID === "") {
      delete newSearchParams["snSuffixSessionID"];
    }
    setIsLoading(true);
    setSearchParams(newSearchParams);
  };
  let snSuffixSessionID = searchParams.get("snSuffixSessionID");

  useEffect(() => {
    fetchData(page, dogId, startDate, endDate, snSuffixSessionID);
    localStorage.removeItem("selectedEvents")
    localStorage.removeItem("selectedVideos")
  }, [dogId, page, searchParams, setEvents]);

  const fetchData = async (
    page,
    dogId,
    startDate,
    endDate,
    snSuffixSessionID
  ) => {
    try {
      const response = await axiosInstance.post(
        "/get-dog-collar-pets-events?pet_id=" + dogId,
        {
          count: 30,
          pet_id: dogId,
          source: source,
          page: page,
          from_date: startDate,
          to_date: endDate,
          snSuffixSessionID: snSuffixSessionID,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEvents(response.data.data);
      // setTotal(response.data.pageInfo.totalPages * 30); // assuming 30 items per page
      setTotal(response.data.pageInfo.totalPages);
      setIsLoading(false);
      localStorage.setItem("selectedEvents", "");
      localStorage.setItem("selectedVideos", "");
    } catch (error) {
      console.error(error);
      toast.error("[API Error]: Request Failed with Status Code 500", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadVideo = () => {
    let selectedVideos = localStorage.getItem("selectedVideos");
    selectedVideos = selectedVideos.split(",");
    selectedVideos = selectedVideos.filter(
      (value) => Object.keys(value).length !== 0
    );
    setTimeout(() => {
      const data = {
        video_urls: selectedVideos,
        device_id: dogId,
      };
      axiosInstance
        .post("/get-dog-collar-videos", JSON.parse(JSON.stringify(data)), {
          responseType: "blob",
        })
        .then((response, error) => {
          if (error) {
            setError(error);
            setIsLoading(false);
            return;
          }
          // setisLoading(false);
          try {
            const blob = new Blob([response.data], { type: "application/zip" });
            saveAs(blob, dogId + ".zip");
          } catch (e) {
            console.log(e);
            toast.error("[API Error]: Request Failed with Status Code 500", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }, 100);
  };

  const downloadCSV = () => {
    let selectedEvents = localStorage.getItem("selectedEvents");
    let data_events = [];
    selectedEvents = selectedEvents.split(",");
    selectedEvents = selectedEvents.filter(
      (value) => Object.keys(value).length !== 0
    );
    selectedEvents.map((event) => {
      data_events.push({
        event_id: event,
        source: "NestleCommercialPetCollar",
      });
    });
    setTimeout(() => {
      const data = {
        events: data_events,
      };
      axiosInstance
        .post(
          "/get-dog-collar-sensor-data-bulkdownload",
          JSON.parse(JSON.stringify(data))
        )
        .then((response, error) => {
          if (error) {
            setError(error);
            setIsLoading(false);
            return;
          }
          try {
            const blob = new Blob([response.data], { type: "application/ccv" });
            saveAs(blob, "dog_collar_events.csv");
          } catch (e) {
            console.log(e);
            toast.error("[API Error]: Request Failed with Status Code 500", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }, 100);
  };

  const refreshEventList = () => {
    setPage(1);
    fetchData(page, dogId, startDate, endDate, snSuffixSessionID);
    setIsLoading(true);
  };
  const [value, setValue] = React.useState(new Date());

  const handleStartDateChange = (date) => {
    if (date != null && date.length > 0) {
      const start = moment(date[0]).format("YYYY-MM-DD");
      setStartDate(start);
      const end = moment(date[1]).format("YYYY-MM-DD");
      setEndDate(end);
      setValue(date);
    } else {
      setValue(new Date());
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Box>
      <Grid item xs={12}>
        <br />
        <SessionIDSearch
          onSearch={handleSearch}
          defaultValue={searchParams.get("snSuffixSessionID") ?? ""}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: isSmallScreen ? "center" : "end",
            alignItems: "start",
            marginTop: isSmallScreen ? 0 : -3.5,
          }}
        >
          <Button key="downloadVideo" onClick={() => refreshEventList(page)}>
            Reload
          </Button>
          <DateRangePicker value={value} onChange={handleStartDateChange} />
          <Button key="filter" onClick={() => refreshEventList(page)}>
            Filter
          </Button>
          <Button key="BulkdownloadVideo" onClick={() => downloadVideo(page)}>
            Download Video
          </Button>
          <Button key="BulkdownloadCSV" onClick={() => downloadCSV(page)}>
            Download CSV
          </Button>
        </Box>
      </Grid>
      <EventTable
        events={events}
        isLoading={isLoading}
        refreshEventList={refreshEventList}
        page={page}
        setPage={setPage}
        total={total}
        source={source}
      />
      <ToastContainer />
    </Box>
  );
};

export default DogCollarEvents;
