import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CircularProgress,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import * as yup from "yup";
import axiosInstance from "./../../../api/axios/axiosInstance";
import { useForm, Controller } from "react-hook-form";

const validationSchema = yup.object({
    name: yup.string(),
});

const EditMonitor = ({ household_id, submitCallback, closeCallback }) => {
    const [isLoading, setIsLoading] = useState(false);


    const { control, handleSubmit, setValue, reset } = useForm({
        
        defaultValues: {
            name: household_id.name,
            frozen_device_ind: household_id.isFrozen,
        }
    });

    const onSubmit = async (values) => {
        setIsLoading(true);
            const params = {
                device_id: household_id.id,
                name: values.name,
                device_start_date: null,
                household_id: household_id.household_id,
                frozen_device_ind: values.frozen_device_ind,
            };
            axiosInstance.post("/update-machine", params).then((response) => {
                setIsLoading(false);
                submitCallback();
              }).catch((error) => {
                setIsLoading(false);
                throw new Error(error);
              });
    };
    




    return (
        <Dialog open onClose={closeCallback}>
            <DialogTitle>Edit Monitors</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: "Please name this cat" }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Controller
                            name="frozen_device_ind"
                            control={control}
                            render={({ field }) => (
                                <FormControl component="fieldset" margin="normal">
                                    <FormLabel component="legend">Device Frozen</FormLabel>
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value={true} control={<Radio />} label="True" />
                                        <FormControlLabel value={false} control={<Radio />} label="False" />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />

                        <DialogActions>
                            <Button onClick={closeCallback} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" disabled={isLoading}>
                                Save Device
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EditMonitor;
