import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Households from "./components/Modules/CatLitterBox/Households/Households";
import Monitors from "./components/Modules/CatLitterBox/Monitors/Monitors";
import Cats from "./components/Modules/CatLitterBox/Cats/Cats";
import Location from "./components/Modules/CatLitterBox/Location/Location";
import "./App.css";
import Layout from "./components/Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosInstance from "./api/axios/axiosInstance";
import redirectToLogin from "./utils/redirectToLogin";
import EventsHome from "./components/Modules/CatLitterBox/Events/Events";
import { QueryParamProvider } from "use-query-params";
import history from "./utils/history";
import Household from "./components/Modules/HouseholdSingleView/HousehildSingleView";
import Cat from "./components/Modules/CatSingleView/CatSingleView";
import Machine from "./components/Modules/MachineSingleView/Machine";
import EventSingleViewEB from "./components/Modules/EventSingleView/EventSingleViewEB";
import DogCollarPets from "./components/Modules/DogCollar/Pets/DogCollarPets";
import DogCollar from "./components/Modules/DogCollarSingleView/DogCollar";
import CatFeeder from "./components/Modules/CatFeeder/Cats/CatFeeder";
import DogCollarHouseholds from "./components/Modules/DogCollar/Households/DogCollarHouseholds";
import DogCollarHouseholdSingleView from "./components/Modules/DogCollarHouseholdSingleView/DogCollarSingleView";
import CatFeederSingleView from "./components/Modules/CatFeederSingleView/CatFeeder";
import CatFeederEventsHome from "./components/Modules/CatFeederEventsView/CatFeederEvents";
import DogCollarEventsHome from "./components/Modules/DogCollarEventsView/DogCollarEvents";
import DogCollarEventSingleViewEB from "./components/Modules/DogCollarEventSingleView/DogCollarEventSingleViewEB";
import CatFeederEventSingleViewEB from "./components/Modules/CatFeederEventSingleView/CatFeederEventSingleViewEB";
import DogBowlPets from "./components/Modules/DogBowl/Pets/DogBowlPets";
import DogBowlEventsHome from "./components/Modules/DogBowl/EventsView/DogBowlEvents";
import DogBowl from "./components/Modules/DogBowl/DogBowlSingleView/DogBowl";
import DogBowlEventSingleViewEB from "./components/Modules/DogBowl/EventsView/DogBowlEventSingleViewEB";

function App() {
  const currentUrl = window.location.href;
  const [isAuthenticated, setisAuthenticated] = useState(false);
  useEffect(() => {
    const path = currentUrl.split("/").filter(Boolean);
    axiosInstance
      .get("/isauthenticated")
      .then((response, error) => {
        if (error) {
          setisAuthenticated(false);
        } else {
          setisAuthenticated(response?.data?.data?.authenticated);
          // window.location.assign(`/login`)
        }
        if (isAuthenticated === false && (path[2] !== "login" || path[2] === "")) {
          redirectToLogin();
        }
      })
      .catch((error) => {
        setisAuthenticated(false);
        if (
          isAuthenticated === false &&
          (path[2] !== "login" || path[2] === "")
        ) {
          redirectToLogin();
        }
      });
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route element={<Layout />}>
        <Route path="/home" element={<Home />} />
        <Route path="/cat-litter-box/households" element={<Households />} />
        <Route path="/cat-litter-box/households/:householdId" element={<Household />}/>
        <Route path="/cat-litter-box/monitors" element={<Monitors />} />
        <Route path="/cat-litter-box/monitors/:machineId" element={<Machine />}/>
        <Route path="/cat-litter-box/cats" element={<Cats />} />
        <Route path="/cat-litter-box/cats/:catId" element={<Cat />} />
        <Route path="/cat-litter-box/events" element={<EventsHome />} />
        <Route path="/cat-litter-box/events/:eventId"  element={ <EventSingleViewEB />} />
        <Route path="/cat-litter-box/location" element={<Location />} />
        <Route path="/dog-collar/dogs" element={<DogCollarPets />} />
        <Route path="/dog-collar/monitors" element={<DogCollarHouseholds />} />
        <Route path="/dog-collar/monitors/:householdId" element={<DogCollarHouseholdSingleView />} />
        <Route path="/dog-collar/dogs/:dogId/:source" element={<DogCollar />} />
        <Route path="/cat-feeder/monitors" element={<CatFeeder />} />
        <Route path="/cat-feeder/monitors/:machineId" element={<CatFeederSingleView />} />
        <Route path="/cat-feeder/events" element={<CatFeederEventsHome />} />
        <Route path="/cat-feeder/events/:eventId" element={<CatFeederEventSingleViewEB />} />
        <Route path="/dog-collar/events" element={<DogCollarEventsHome />} />
        <Route path="/dog-collar/events/:eventId/:dogId/:source"  element={ <DogCollarEventSingleViewEB />} />
        <Route path="/dog-bowl/dogs" element={<DogBowlPets />} />
        <Route path="/dog-bowl/events" element={<DogBowlEventsHome />} />
        <Route path="/dog-bowl/events/:eventId" element={<DogBowlEventSingleViewEB />} />
        <Route path="/dog-bowl/dogs/:dogId" element={<DogBowl />} />
        
      </Route>
    </Routes>
  );
}

export default App;
