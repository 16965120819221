import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    IconButton,
    Paper,
    TablePagination,
    Box,
    Select,
    MenuItem,
    Typography
} from "@mui/material";
import { styled } from "@mui/system";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { Link, useSearchParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';
import TableCellCatName from "../../TableCellCatName/component";
import TableCellSeenByUser from "../../TableCellSeenByUser/component";
import TableCellHasPushMessage from "../../TableCellHasPushMessage/component";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Time = ({ children }) => {
    return <span>{moment(children).format("LLL")}</span>;
};

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};



const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const CatPedtResultsTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    // updateParentState,
    // isupdateTable,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };


    const forwardTableChangeToSearchParams = (pagination) => {
        

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    const CopyToClipboardButton = ({ textToCopy, testId }) => (
        <CopyToClipboard text={textToCopy}>
          <Tooltip title="Copy to clipboard">
            <IconButton data-testid={testId}>
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      );

    let columns = [
        {
            title: "End Date",
            dataIndex: "endDate",
            width: 150,
            sorter: true,
            // defaultSortOrder: getDefaultSortOrderForColumn("endDate"),
        },
        {
            title: "Generated At",
            dataIndex: "generatedAt",
            
            // defaultSortOrder: getDefaultSortOrderForColumn("generatedAt"),
        },

        {
            title: "Period",
            dataIndex: "period",
            render: function Period(period) {
                if (!period) {
                    return "-"
                }

                return `${period} day${period === 1 ? "" : "s"}`
            },
        },
        {
            title: "Push Message?",
            dataIndex: "hasPushMessage",
            render: function HasPushMessage(hasPushMessage) {
                return (
                    <TableCellHasPushMessage hasPushMessage={hasPushMessage} />
                )
            },
            filters: [
                {
                    text: "Show all",
                    value: "showAllPEDTResults.true",
                },
            ],
            filterMultiple: false,
            // defaultFilteredValue: getDefaultFilteredValuesForColumn(
            //     "hasPushMessage",
            // ),
        },
        {
            title: "Seen by user",
            dataIndex: "seenByUser",
            key: "seenByUser",
            render: function SeenByUser(seenByUser, record) {
                return (
                    <TableCellSeenByUser
                        hasPushMessage={record.hasPushMessage}
                        seenByUser={seenByUser}
                    />
                )
            },
            filters: [
                {
                    text: "Seen",
                    value: "seenByUser.true",
                },
                {
                    text: "Not seen",
                    value: "seenByUser.false",
                },
            ],
            filterMultiple: false,
            // defaultFilteredValue: getDefaultFilteredValuesForColumn(
            //     "seenByUser",
            // ),
        },
        {
            title: "Cat Name",
            dataIndex: "cat",
            render: function Cat(cat) {
                let url = `/cats/${cat?.id ?? ""}`
                return <TableCellCatName name={cat?.name} url={url} />
            },
            width: 180,
        },
        {
            title: "Cat Start weight",
            dataIndex: "startWeight",
            // eslint-disable-next-line react/display-name
            // render: (startWeight) => (
            //     <TableCellCatWeight weight={startWeight} />
            // ),
        },
        {
            title: "Cat End weight",
            dataIndex: "endWeight",
            // eslint-disable-next-line react/display-name
            // render: (endWeight) => (
            //     <TableCellCatWeight weight={endWeight} />
            // ),
        },
        {
            title: "Cat Weight Change",
            dataIndex: "percentageChange",
            render: function PercentageChange(percentage) {
                if (typeof percentage === "number")
                    return `${percentage.toFixed(0)}%`
                return ""
            },
        },
        {
            title: "PEDT Result ID",
            dataIndex: "id",
            key: "id",
            render: function Id(id, record) {
                if (!record.pedtId) return "-"
                const endOfIdString = `...${String(record.pedtId).slice(-6)}`
                return (
                    <Typography
                  variant="body2"
                  component="div"
                  style={{ display: "flex", alignItems: "center" }}
                >
                    <Typography
                        copyable={{ text: record.pedtId }}
                        style={{ display: "inline-flex" }}
                    >
                        {endOfIdString}
                    </Typography>
                    <CopyToClipboardButton
                    textToCopy={record.pedtId}
                    testId={"tids.eventView.copyEventIdToClipboard"}
                  />
                  </Typography>
                )
            },
        },
    ]

    if (columnsFn) {
        columns = columnsFn(columns);
    }
    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };

    return (
        <FullWidthPaper>
            <FullWidthTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={true}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default CatPedtResultsTable;
