import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    TextField,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import axiosInstance from "../../api/axios/axiosInstance";

const LocationCreateModal = ({
    isModalOpen,
    closeModal,
    machine,
    facilitypanelData,
    ptcCats
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);

    let facility = machine?.facility ?? ""
    let panel = machine?.panel ?? ""


    let cats = machine?.pets?.map((pet) => pet.cat_name) ?? []

    const [form, setForm] = useState({
        cats: cats,
        panel: "",
        facility: ""
    });



    const handleFormChange = (field) => (event) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: event.target.value,
        }));
    };

    const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            device_id: machine?.device_id ?? "",
            cats: cats ?? "",
            condo: machine?.condo ?? "",
            facility: machine?.facility ?? "",
            panel: machine?.panel ?? "",
            active_ind: machine?.active_ind ?? ""
        }
    });

    const addLocation = (data) => {
        axiosInstance.post('/create-locations', data)
            .then((response) => {
                if (response.data.message === 'success') {
                    closeModal();
                    setIsLoading(false);
                }
                else {
                    setErrorMessage(response.data.message)

                }
                setIsLoading(false);

            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const updateLocation = (data) => {
        axiosInstance.post('/update-locations', data).then((response, error) => {
            setIsLoading(false);
            if (response.data.message === 'success') {

                closeModal()
                setIsLoading(false);

            }
            setIsLoading(false);
        })
    }


    const onSubmit = async (values) => {
        setIsLoading(true);
        setIsSaving(true);
        try {

            if (machine) {
                let catList = []
                if (form.facility != "") {
                    facility = form.facility
                }
                if (form.panel != "") {
                    panel = form.panel
                }
                const data = {
                    "device_id": machine.device_id,
                    "condo": values?.condo ?? "",
                    "facility": facility,
                    "cats": form.cats,
                    "active": values.active_ind,
                    "panel": panel
                }
                updateLocation(data);
            }
            else {
                const data = {
                    "device_id": values.device_id,
                    "condo": values?.condo ?? "",
                    "facility": form.facility,
                    "cats": form.cats,
                    "active": values.active_ind,
                    "panel": form.panel
                }
                addLocation(data);
            }
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        } finally {
            setIsSaving(false);
        }
    };

    const facilityData = facilitypanelData.facility
    const panelData = facilitypanelData.panels


    return (
        <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>
                {machine
                    ? `Edit ${machine.device_id}`
                    : `Add New Location`}
            </DialogTitle>
            <DialogContent>
                {isLoading && <CircularProgress />}
                {!isLoading && (
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {!machine && (
                            <Controller
                                name="device_id"

                                control={control}
                                rules={{ required: true }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        label="Device ID"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        error={!!errors.device_id}
                                        helperText={errors.device_id && `${errors.device_id.message}`}
                                    />
                                )}
                            />
                        )}
                        {errorMessage && (
                            <span style={{ color: 'red' }}>{errorMessage}</span>
                        )}
                        <InputLabel id="type-label">Cat</InputLabel>
                        <Controller
                            name="cats"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id="cats"
                                    style={{ width: "100%" }}
                                    multiple
                                    // label={"Select Itens"}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder={"Select Itens"}
                                    defaultValue={cats}
                                    labelId="Cats-label"
                                    onChange={handleFormChange("cats")}
                                >
                                    {ptcCats.map((data, i) => (
                                        <MenuItem key={i} value={data.cat_name}>
                                            {data.cat_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />

                        <Controller
                            name="condo"

                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    label="Condo"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />
                        {/* <InputLabel id="type-label">Panel</InputLabel> */}
                        <Controller
                            name="panel"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }, fieldState) => (
                                <Select
                                    id="panel"
                                    style={{ width: "100%", marginTop: "10px" }}
                                    placeholder="Panel"
                                    defaultValue={panel}
                                    onChange={handleFormChange("panel")}
                                >
                                    {panelData.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />
                        {/* <InputLabel id="type-label">Facility</InputLabel> */}
                        <Controller
                            name="facility"
                            control={control}
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }, fieldState) => (
                                <Select
                                    id="facility"
                                    style={{ width: "100%", marginTop: "10px" }}
                                    placeholder="Facility"
                                    defaultValue={facility}
                                    onChange={handleFormChange("facility")}
                                >
                                    {facilityData.map((data, i) => (
                                        <MenuItem key={i} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )} />

                        <Controller
                            name="active_ind"
                            control={control}
                            render={({ field }) => (
                                <FormControl component="fieldset" margin="normal">
                                    <FormLabel component="legend">Active</FormLabel>
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value={true} control={<Radio />} label="Active" />
                                        <FormControlLabel value={false} control={<Radio />} label="Deactivate" />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />

                        <DialogActions>
                            <Button onClick={closeModal} color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSaving}
                            // data-testid={tids.addCat.saveButton}
                            >
                                {machine ? "Save Location" : "Add Location"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default LocationCreateModal;
