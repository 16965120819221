import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    IconButton,
    Paper,
    TablePagination,
    Typography,
    Popover,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import CustomHighlighter from "./../../../utils/CustomHighlighter";
import calculateAge from "./../../../utils/calculateAge";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useMassFormatter, useMassUnits } from "../../../utils/context/mass";

const CustomTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const NoData = styled("div")({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled("ul")({
    margin: 0,
    paddingLeft: "1rem",
});

const CustomTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    latestEvent: "LATEST_EVENT",
    pedtTriggersCount: "PEDT_TRIGGERS_COUNT",
    pedtResultsNotificationsCount: "PEDT_NOTIFICATIONS_COUNT",
};

const SORT_DIRS = {
    ascend: "asc",
    descend: "desc",
};

const DogCollarPetsTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    ...props
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { massFormatter } = useMassFormatter()
    const massUnits = useMassUnits()

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "asc";
        if (sortSuffixesRegex.groups?.descend) return "desc";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        if (!appliedSort.includes(columnSortField)) return null;
        const direction = getDirectionFromSortString(appliedSort);
        if (!direction) return null;
        return direction;
    };

    // const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
    //     const getTrainedStateFilters = () => {
    //         return searchParams.getAll("trainedState");
    //     };
    //     return {
    //         trainedState: getTrainedStateFilters(),
    //     };
    // };
    // const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(searchParams);
    // const getDefaultFilteredValuesForColumn = (colIndex) =>
    //     defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, filters, sorter) => {
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };
        const getTrainedState = (filters) => {
            return filters;
        };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
            trainedState: getTrainedState(filters.trainedState ?? []),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();

        setSearchParams(new URLSearchParams(newSearchParams));
    };

    let columns = [
        {
            title: "Pet ID",
            dataIndex: "pet_id",
            render: function petID(pet_id, record)  {
                return (
                <Link to={`/dog-collar/dogs/${record.pet_id}/${record.source_key}`}>
                    <CustomHighlighter
                        searchWords={[searchParams.get("catName")]}
                        textToHighlight={record.pet_id ?? ""}
                        highlightStyle={{ backgroundColor: "#ffe58f" }}
                    />
                </Link>
                )
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            render: function CatName(name, record) {
                const cat_deactivated = record.cat_deactivated;
                return (
                    <Box display="flex">
                        <Link to={`/dog-collar/dogs/${record.pet_id}/${record.source_key}`}>
                            <CustomHighlighter
                                searchWords={[searchParams.get("catName")]}
                                textToHighlight={name ?? ""}
                                highlightStyle={{ backgroundColor: "#ffe58f" }}
                            />
                        </Link>
                        {cat_deactivated && (
                            <Box ml={1}>
                                <Tooltip title="">
                                    <VisibilityIcon
                                        fontSize="small"
                                        title="Cat is Disabled - Use Deployments to Reactivate"
                                    />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                );
            },
        },
        {
            title: "Age",
            dataIndex: "birth_date",
            render: (birth_date) => {
                const dobMoment = moment(birth_date)

                if (dobMoment.isValid()) {
                    const age = calculateAge(dobMoment)
                    return `${age} year${age > 1 ? "s" : ""}`
                } else {
                    return "Unknown"
                }
            },
        },
        {
            title: "Age Range",
            dataIndex: "age_range",
            render: (age_range) => {
                return age_range
            },
        },
        {
            title: "Gender",
            dataIndex: "gender",
            render: (gender) => {
                return gender;
            },
        },
        {
            title: "Weight",
            dataIndex: "weight",
            render: (weight) => {
                // return weight;
                return `${massFormatter(weight)}${massUnits}`
            },
        },
        {
            title: "Height (Inches)",
            dataIndex: "height",
            render: (height) => {
                return height;
            },
        },
        {
            title: "Breed",
            dataIndex: "breed",
            render: (breed) => {
                return breed;
            },
        },
        {
            title: "Size",
            dataIndex: "size",
            render: (size) => {
                return size;
            },
        },
        {
            title: "Total Events",
            dataIndex: "total_events",
            render: (total_events) => {
                return total_events;
            },
        },
        {
            title: "Latest Event",
            dataIndex: "latest_event",
            render: (latest_event) => {
                return latest_event;
            },
        },
        {
            title: "Source",
            dataIndex: "source",
            render: (source) => {
                return source;
            },
        },

        {
            title: "Active",
            dataIndex: "active",
            render: function CatActive(active, record) {
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        {active === false ? (
                            <span>Not active</span>

                        ) : (
                            <span>Yes</span>
                        )}
                    </div>
                )
            },
        },
        // {
        //     title: "Action",
        //     dataIndex: "action",
        //     render: function CatActions(_, record) {
        //         return (
        //             <Box display="flex">
        //                 <EditCatButton
        //                     updateParentState={updateParentState}
        //                     isupdateTable={isupdateTable}
        //                     existingCat={record}
        //                 />
        //             </Box>
        //         );
        //     },
        // },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }

    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                ...defaultPagination,
                ...pagination,
            };


    return (
        <CustomPaper>
            <CustomTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <CustomTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={column.key === 'latestEvent'}
                                            direction={getDefaultSortOrderForColumn(column.key) || 'asc'}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}

                    </TableBody>

                </Table>
                {data?.length == 0 &&
                    <Typography variant="h6" style={{ marginLeft: "640px", width: "50%", padding: "10px" }}> No Data Found </Typography>
                }
            </CustomTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    component="div"
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <VisibilityIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
                // </CustomTablePagination>
            )}
        </CustomPaper>
    );
};

export default DogCollarPetsTable;
