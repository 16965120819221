import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    IconButton,
    Paper,
    TablePagination,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from 'moment-timezone';
import { findChunksAtEndOfString } from "../../../utils/findChunksAtEndOfString";
import { Edit as EditIcon } from "@mui/icons-material";
import EditMonitor from "../../Modules/HouseholdSingleView/EditMonitor";
import PowerStatus from "../../Modules/PowerStatus/PowerStatus";
import TimeSinceDate from "../../TimeSinceDate/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableCellWifiRssi from "../../Modules/TableCellWifiRssi/TableCellWifiRssi";

const NoData = styled('div')({
    opacity: 0.4,
    fontStyle: "italic",
});

const Ul = styled('ul')({
    margin: 0,
    paddingLeft: "1rem",
});

const ActionIconButton = styled(IconButton)({
    padding: 0,
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
});

const FullWidthPaper = styled(Paper)({
    width: '100%',
    overflowX: 'auto',
});

const FullWidthTableContainer = styled(TableContainer)({
    width: '100%',
});

const CustomPagination = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
});

const CustomTablePagination = (props) => {
    const {
        count,
        page,
        rowsPerPage,
        onPageChange,
        onRowsPerPageChange,
        rowsPerPageOptions,
        labelDisplayedRows,
        labelRowsPerPage,
        pagination
    } = props;

    return (
        <CustomPagination>
            <Box display="flex" alignItems="center">
                <Box>{labelRowsPerPage}</Box>
                <Select
                    value={rowsPerPage}
                    onChange={onRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: 8, marginRight: 16 }}
                >
                    {rowsPerPageOptions.map((rowsPerPageOption) => (
                        <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                            {rowsPerPageOption}
                        </MenuItem>
                    ))}
                </Select>
                <Box>{labelDisplayedRows({ from: page * rowsPerPage + 1, to: Math.min(count, (page + 1) * rowsPerPage), count })}</Box>
            </Box>
            <Box display="flex" alignItems="center" marginLeft={2}>
                <IconButton
                    onClick={(event) => onPageChange(event, page - 1)}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                    onClick={(event) => onPageChange(event, page + 1)}
                    disabled={page >= Math.ceil(pagination.total / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </CustomPagination>
    );
};

const SORT_FIELDS = {
    sn: "SN",
    name: "NAME",
    power: "BATTERY_PERCENTAGE",
    mostRecentUpload: "MOST_RECENT_UPLOAD_AT",
    stFirmwareRevision: "ST_FIRMWARE_REVISION",
    espFirmwareRevision: "ESP_FIRMWARE_REVISION",
    hardwareRevision: "HARDWARE_REVISION",
    wifiRssi: "WIFI_RSSI",
    firstConnectedAt: "FIRST_CONNECTED_AT",
    snFirstConnectedAt: "SN_FIRST_CONNECTED_AT",
    frozenAt: "FROZEN_AT",
};

const SORT_DIRS = {
    ascend: "ASC",
    descend: "DESC",
};

const useStyles = styled({
    monitorNameAndEdit: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
    },
});

const MachinesTable = ({
    data = [],
    loading = false,
    columnsFn,
    pagination,
    updateParentState,
    isupdateTable,
    revisionOptions: {
        existingStFirmwareRevisions = [],
        existingEspFirmwareRevisions = [],
        existingHardwareRevisions = [],
    } = {},
    ...props
}) => {
    const styles = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newMonitorModalIsOpen, setMonitorModalIsOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const timezone = 'UTC';  // Assuming a timezone value, replace it with your logic

    const formatDateBySelectedTimezone = (date) =>
        moment(date + "Z")
            .tz(timezone)
            .format("YYYY-MM-DDTHH:mm:ss Z");

    const getDirectionFromSortString = (sortString) => {
        const sortSuffixesRegex = sortString.match(
            /.*(?:(?<ascend>_ASC)|(?<descend>_DESC))/
        );
        if (sortSuffixesRegex.groups?.ascend) return "ascend";
        if (sortSuffixesRegex.groups?.descend) return "descend";
        return null;
    };

    const getDefaultSortOrderForColumn = (colIndex) => {
        const appliedSort = searchParams.get("sort") ?? "SN_ASC";
        const columnSortField = SORT_FIELDS[colIndex];
        if (!appliedSort.includes(columnSortField)) return null;
        const direction = getDirectionFromSortString(appliedSort);
        if (!direction) return null;
        return direction;
    };

    const translateSearchParamsToDefaultFilteredValues = (searchParams) => {
        const getPowerModeFilters = () => {
            const powerModeFilters = searchParams
                .getAll("powerMode")
                .map((filter) => "powerMode." + filter);
            const showBatteryWarningFilters = searchParams
                .getAll("showBatteryWarning")
                .map((filter) => "showBatteryWarning." + filter);

            return powerModeFilters.concat(showBatteryWarningFilters);
        };
        const getMostRecentUploadFilters = () => {
            const mostRecentUploadWarning = searchParams.get(
                "mostRecentUploadWarning"
            );
            if (mostRecentUploadWarning === "true")
                return ["mostRecentUploadWarning.true"];
            if (mostRecentUploadWarning === "false")
                return ["mostRecentUploadWarning.false"];
            return [];
        };
        const getStFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("stFirmwareRevision");
            return revisions;
        };
        const getEspFirmwareRevisionFilters = () => {
            const revisions = searchParams.getAll("espFirmwareRevision");
            return revisions;
        };
        const getHardwareRevisionFilters = () => {
            return searchParams.getAll("hardwareRevision");
        };
        const getFrozenAtFilters = () => {
            const isFrozen = searchParams.get("isFrozen");
            if (isFrozen === "true") return ["isFrozen.true"];
            if (isFrozen === "false") return ["isFrozen.false"];
            return [];
        };
        const getOrphanedAtFilters = () => {
            const isOrphaned = searchParams.get("isOrphaned");
            if (isOrphaned === "true") return ["isOrphaned.true"];
            if (isOrphaned === "false") return ["isFrozen.false"];
            return [];
        };

        return {
            powerMode: getPowerModeFilters(),
            mostRecentUpload: getMostRecentUploadFilters(),
            stFirmwareRevision: getStFirmwareRevisionFilters(),
            espFirmwareRevision: getEspFirmwareRevisionFilters(),
            hardwareRevision: getHardwareRevisionFilters(),
            frozenAt: getFrozenAtFilters(),
            orphanedAt: getOrphanedAtFilters(),
        };
    };
    const defaultFilteredValuesByColumn = translateSearchParamsToDefaultFilteredValues(
        searchParams
    );
    const getDefaultFilteredValuesForColumn = (colIndex) =>
        defaultFilteredValuesByColumn[colIndex];

    const forwardTableChangeToSearchParams = (pagination, sorter) => {
        const getSort = (sorter) => {
            if (!(sorter.field && sorter.order)) {
                return undefined;
            }
            return SORT_FIELDS[sorter.field] + "_" + SORT_DIRS[sorter.order];
        };
        // const getIsFrozen = (filters) => {
        //     if (filters.includes("isFrozen.true")) return true;
        //     if (filters.includes("isFrozen.false")) return false;
        //     return undefined;
        // };
        // const getIsOrphaned = (filters) => {
        //     if (filters.includes("isOrphaned.true")) return true;
        //     if (filters.includes("isOrphaned.false")) return false;
        //     return undefined;
        // };
        // const getPowerMode = (filters) => {
        //     let powerModeSearchParams = [];
        //     if (filters.includes("powerMode.AC")) {
        //         powerModeSearchParams.push("AC");
        //     }
        //     if (filters.includes("powerMode.BATTERY")) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     if (
        //         filters.includes("showBatteryWarning.true") ||
        //         filters.includes("showBatteryWarning.false")
        //     ) {
        //         powerModeSearchParams.push("BATTERY");
        //     }
        //     return powerModeSearchParams;
        // };
        // const getShowBatteryWarning = (filters) => {
        //     let showBatteryWarningSearchParams = [];
        //     if (filters.includes("showBatteryWarning.true")) {
        //         showBatteryWarningSearchParams.push(true);
        //     }
        //     if (filters.includes("showBatteryWarning.false")) {
        //         showBatteryWarningSearchParams.push(false);
        //     }
        //     return showBatteryWarningSearchParams;
        // };
        // const getMostRecentUploadWarning = (filters) => {
        //     if (filters.includes("mostRecentUploadWarning.true")) return true;
        //     if (filters.includes("mostRecentUploadWarning.false")) return false;
        //     return undefined;
        // };
        // const getStFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getEspFirmwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };
        // const getHardwareRevision = (filters) => {
        //     const selectedRevisionNumbers = filters;
        //     return selectedRevisionNumbers;
        // };

        const newSearchParams = {
            ...Object.fromEntries(searchParams),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSort(sorter),
            // isFrozen: getIsFrozen(filters.frozenAt ?? []),
            // isOrphaned: getIsOrphaned(filters.orphanedAt ?? []),
            // powerMode: getPowerMode(filters.power ?? []),
            // showBatteryWarning: getShowBatteryWarning(filters.power ?? []),
            // mostRecentUploadWarning: getMostRecentUploadWarning(
            //     filters.mostRecentUpload ?? []
            // ),
            // stFirmwareRevision: getStFirmwareRevision(
            //     filters.stFirmwareRevision ?? []
            // ),
            // espFirmwareRevision: getEspFirmwareRevision(
            //     filters.espFirmwareRevision ?? []
            // ),
            // hardwareRevision: getHardwareRevision(
            //     filters.hardwareRevision ?? []
            // ),
        };

        function removeUndefinedSearchParams() {
            Object.entries(newSearchParams).forEach(([key, value]) => {
                if (value === undefined) delete newSearchParams[key];
            });
        }
        removeUndefinedSearchParams();

        function resetPageToOneIfNeeded() {
            if (Number(searchParams.get("page")) !== pagination.current) return;
            newSearchParams.page = 1;
        }
        resetPageToOneIfNeeded();
        setSearchParams(newSearchParams);
    };

    let columns = [
        {
            title: "Serial No.",
            dataIndex: "sn",
            width: 200,
            render: function MachineSerial(serial, record) {
                return (
                    <div style={{ display: "flex" }}>
                        <Link to={`/cat-litter-box/monitors/${record.id}`}>
                            <Highlighter
                                searchWords={[searchParams.get("snSuffix")]}
                                textToHighlight={serial ?? ""}
                                findChunks={findChunksAtEndOfString}
                                highlightStyle={{backgroundColor: "#ffe58f"}}
                            />
                        </Link>
                        {record.isFrozen && (
                            <Tooltip
                                title={
                                    record.frozen
                                        ? `Frozen at ${formatDateBySelectedTimezone(
                                              record.frozenAt
                                          )}`
                                        : "Frozen"
                                }
                            >
                                <Box ml={1}>
                                    <VisibilityIcon fontSize="small" />
                                </Box>
                            </Tooltip>
                        )}
                        {record.isOrphaned && (
                            <Tooltip
                                title={
                                    record.orphaned
                                        ? `Orphaned at ${formatDateBySelectedTimezone(
                                              record.orphanedAt
                                          )}`
                                        : "Orphaned"
                                }
                            >
                                <Box ml={1}>
                                    <VisibilityIcon fontSize="small" />
                                </Box>
                            </Tooltip>
                        )}
                    </div>
                );
            },
            fixed: "left",
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("sn"),
        },
        {
            title: "Name",
            dataIndex: "name",
            render: function MonitorName(name, record) {
                return (
                    <Box className={styles.monitorNameAndEdit}>
                        <span>{name ?? "-"}</span>
                        <span>
                            {/* Add your EditMonitorName component here */}
                        </span>
                    </Box>
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("name"),
        },
        {
            title: "Power",
            dataIndex: "power",
            // render: function PowerStatusColumn(_, record) {
            //     return (
            //         <Box>
            //             {/* Add your PowerStatus component here */}
            //         </Box>
            //     );
            // },
            render: function PowerStatusColumn(_, record) {
                return (
                    <Box>
                    <PowerStatus
                        powerMode={record.powerMode}
                        batteryPercentage={record.batteryPercentage}
                        showBatteryWarning={record.showBatteryWarning}
                    />
                    </Box>
                )
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("power"),
            filters: [
                {
                    text: "AC",
                    value: "powerMode.AC",
                },
                {
                    text: "Battery",
                    value: "powerMode.BATTERY",
                },
                {
                    text: "Low (< 30%)",
                    value: "showBatteryWarning.true",
                },
                {
                    text: "High (>= 30%)",
                    value: "showBatteryWarning.false",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: getDefaultFilteredValuesForColumn(
                "powerMode"
            ),
        },
        {
            title: "Last Upload",
            dataIndex: "mostRecentUpload",
            width: 140,
            render: function LastUpload(_, record) {
                return record.mostRecentUploadAt ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "4px" }}>
                            <TimeSinceDate
                                dateString={record.mostRecentUploadAt}
                            />
                        </div>
                        {record.mostRecentUploadWarning ? (
                            <Tooltip
                                title={
                                    "Has not been seen in expected time range"
                                }
                            >
                                <FontAwesomeIcon
                                    icon="exclamation-triangle"
                                    title="Has not been seen in expected time range"
                                />
                            </Tooltip>
                        ) : null}
                    </div>
                ) : (
                    "Never"
                )
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("mostRecentUpload"),
            filters: [
                {
                    text: "Has Warning",
                    value: "mostRecentUploadWarning.true",
                },
                {
                    text: "No Warning",
                    value: "mostRecentUploadWarning.false",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: getDefaultFilteredValuesForColumn(
                "mostRecentUpload"
            ),
        },
        {
            title: "ST FW Rev",
            dataIndex: "stFirmwareRevision",
            width: 90,
            render: function MachineSTMFWRev(stFirmwareRevision, record) {
                return (
                    <Box display="flex" alignItems="center">
                        <span style={{ marginRight: "4px" }}>
                            {stFirmwareRevision ?? "-"}
                        </span>
                        {record.stFirmwareUpgradeAvailable && (
                            <Tooltip
                                title={`Upgrade available to ${record.stFirmwareUpgradeRevision}`}
                            >
                                <VisibilityIcon fontSize="small" />
                            </Tooltip>
                        )}
                    </Box>
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn(
                "stFirmwareRevision"
            ),
            filters: [
                {
                    text: "Revisions",
                    value: "revision",
                    children: existingStFirmwareRevisions.map((option) => ({
                        text: option,
                        value: option,
                    })),
                },
            ],
            filterMode: "tree",
            defaultFilteredValue: getDefaultFilteredValuesForColumn(
                "stFirmwareRevision"
            ),
        },
        {
            title: "ESP FW Rev",
            dataIndex: "espFirmwareRevision",
            width: 90,
            render: function MachineESPFWRev(espFirmwareRevision, record) {
                return (
                    <Box display="flex" alignItems="center">
                        <span style={{ marginRight: "4px" }}>
                            {espFirmwareRevision ?? "-"}
                        </span>
                        {record.espFirmwareUpgradeAvailable && (
                            <Tooltip
                                title={`Upgrade available to ${record.espFirmwareUpgradeRevision}`}
                            >
                                <VisibilityIcon fontSize="small" />
                            </Tooltip>
                        )}
                    </Box>
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn(
                "espFirmwareRevision"
            ),
            filters: [
                {
                    text: "Revisions",
                    value: "revision",
                    children: existingEspFirmwareRevisions.map((option) => ({
                        text: option,
                        value: option,
                    })),
                },
            ],
            filterMode: "tree",
            defaultFilteredValue: getDefaultFilteredValuesForColumn(
                "espFirmwareRevision"
            ),
        },
        {
            title: "HW Rev",
            width: 85,
            dataIndex: "hardwareRevision",
            render: (hardwareRevision) => hardwareRevision ?? "-",
            filters: [
                {
                    text: "Revisions",
                    value: "revision",
                    children: existingHardwareRevisions.map((option) => ({
                        text: option,
                        value: option,
                    })),
                },
            ],
            filterMode: "tree",
            defaultFilteredValue: getDefaultFilteredValuesForColumn(
                "hardwareRevision"
            ),
        },
        {
            title: "Wifi RSSI",
            dataIndex: "wifiRssi",
            render: (wifiRssi) => <TableCellWifiRssi wifiRssi={wifiRssi} />,
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("wifiRssi"),
            width: 140,
        },
        {
            title: "First Connected",
            dataIndex: "firstConnectedAt",
            width: 140,
            render: function FirstConnected(firstConnectedAt) {
                return firstConnectedAt ? (
                    <Box>
                        <TimeSinceDate dateString={firstConnectedAt} />
                    </Box>
                ) : (
                    "-"
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("firstConnectedAt"),
        },
        {
            title: "SN First Connected",
            dataIndex: "snFirstConnectedAt",
            width: 140,
            render: function SNFirstConnected(snFirstConnectedAt) {
                return snFirstConnectedAt ? (
                    <Box>
                        <TimeSinceDate dateString={snFirstConnectedAt} />
                    </Box>
                ) : (
                    "-"
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn(
                "snFirstConnectedAt"
            ),
        },
        {
            // title: function FrozenColumnTitle() {
            //     return (
            //         <Box display="flex" alignItems="center">
            //             <span>Frozen</span>
            //             <VisibilityIcon fontSize="small" />
            //         </Box>
            //     );
            // },
            title: "Frozen",
            dataIndex: "frozenAt",
            width: 80,
            render: function isFrozenColumn(frozenAt, record) {
                if (!record.isFrozen) return "-";
                return (
                    <Box>
                        {/* Add your TimeSinceDate component here */}
                    </Box>
                );
            },
            sorter: true,
            defaultSortOrder: getDefaultSortOrderForColumn("frozenAt"),
            filters: [
                {
                    text: "Not Frozen",
                    value: "isFrozen.false",
                },
                {
                    text: "Is Frozen",
                    value: "isFrozen.true",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: getDefaultFilteredValuesForColumn("frozenAt"),
        },
        {
            // title: function OrphanedColumnTitle() {
            //     return (
            //         <Box display="flex" alignItems="center">
            //             <span>Orphaned</span>
            //             <VisibilityIcon fontSize="small" />
            //         </Box>
            //     );
            // },
            title: "Orphaned",
            dataIndex: "orphanedAt",
            width: 80,
            render: function isOrphanedColumn(orphanedAt, record) {
                if (!record.isOrphaned) return "-";
                return "Yes";
            },
            filters: [
                {
                    text: "Not Orphaned",
                    value: "isOrphaned.false",
                },
                {
                    text: "Is Orphaned",
                    value: "isOrphaned.true",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: getDefaultFilteredValuesForColumn(
                "orphanedAt"
            ),
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: function renderActionButton(name, record) {
              return (
                <Box display="flex">
                        <EditIcon onClick={() => setEdit(true)} style={{ color: "#1976d2", cursor: "pointer" }} />
                        {edit ? (
                        <EditMonitor
                          household_id={record}
                          machine={record}
                          submitCallback={() => {
                            updateParentState(isupdateTable+1)
                            setEdit(false);
                          }}
                          closeCallback={() => {
                            setEdit(false);
                          }}
                        />
                      ) : null}
                    </Box>
                    
              );
            },
          },
    ];

    if (columnsFn) {
        columns = columnsFn(columns);
    }
    const defaultPagination = {
        position: ["topRight", "bottomRight"],
        defaultCurrent: 1,
        defaultPageSize: 50,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
    };
    const tablePagination =
        pagination === false
            ? false
            : {
                  ...defaultPagination,
                  ...pagination,
              };

    return (
        <FullWidthPaper>
            <FullWidthTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <StyledTableCell key={column.key}>
                                    {column.sorter ? (
                                        <TableSortLabel
                                            active={true}
                                            direction={getDefaultSortOrderForColumn(column.dataIndex) || 'ascend'}
                                            onClick={() => forwardTableChangeToSearchParams(pagination, column.sorter)}
                                            // onChange={forwardTableChangeToSearchParams}
                                        >
                                            {column.title}
                                        </TableSortLabel>
                                    ) : (
                                        column.title
                                    )}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.key}>
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                
            </FullWidthTableContainer>
            {pagination !== false && (
                <CustomTablePagination
                    count={data.length}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.current - 1}
                    onPageChange={(event, newPage) => forwardTableChangeToSearchParams({ current: newPage + 1, pageSize: pagination.pageSize }, {}, {})}
                    onRowsPerPageChange={(event) => forwardTableChangeToSearchParams({ current: 1, pageSize: parseInt(event.target.value, 10) }, {}, {})}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="View"
                    labelDisplayedRows={({ from, to, count }) => (
                        <Box display="flex" alignItems="center">
                            <Box>{`Found ${count.toLocaleString()} Records`}</Box>
                            <Box display="flex" alignItems="center" mx={2}>
                                <WatchLaterIcon fontSize="small" />
                                {/* <Box mx={1}>{`${from} - ${to}`}</Box> */}
                                <Box mx={1}>{`${from} - ${Math.ceil(from + 49)}`}</Box>
                            </Box>
                        </Box>
                    )}
                    pagination={pagination}
                />
            )}
        </FullWidthPaper>
    );
};

export default MachinesTable;
